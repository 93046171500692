<template>
      <div class="privacyAgreement">
  
    <div class="privacyAgreement-box">
       <div v-html="chxy.content"></div>
    </div>
  </div>
</template>

<script>
import {getchxy} from "@/api/index"
export default {
  name: "privacyAgreement",
  components: {
   
  },
  data() {
    return {
        chxy:""
    
    }
  },
  mounted(){
    getchxy().then(res=>{
        this.chxy = res.data[2]
      console.log(res.data);
    })
  },
    created(){
   
  },
};
</script>

<style lang="less" scoped>
.privacyAgreement {
    background: #fff;
  .privacyAgreement-box{
    width: 1200px;
    margin: 70px auto;
    margin-bottom: 125px;
    display: flex;
    flex-wrap: wrap;
    img{
        width: 160px;
        margin-right: 10px;
        margin-bottom: 13px;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 0px 18px 1px rgba(0,0,0,0.07);
        }
    }
  }
}

@media screen and (max-width: 1050px) {
  .privacyAgreement-box{
    width: 100% !important;
    justify-content: space-evenly;

  }
}
</style>